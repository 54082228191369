import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import { Link } from 'gatsby'
import SocialNetworks from '@components/social-networks'

export default function page404(props) {
  return (
    <Layout location={props.location} title='Wyeworks | 404' namespace='p404'>
      <div className='p404-wrapper'>
        <div className='hero hero-1'>
          <div className='hero-1__col-1'>
            <div className='title-label-1'>
              <div className='title-label-1__count'>
                404
              </div>
              <div className='title-label-1__seperator'></div>
              <div className='title-label-1__label'>
                Unknown URL
              </div>
            </div>
            <h1 className='hero-1__title'>
              Oh No! That Page Wasn't Found
            </h1>
            <p>
              We can't seem to find the page you're looking for. Try going back to the previous page or
              <Link to='/contact-us' className='p404-hero__link'>
                &nbsp;contact us&nbsp;
              </Link>
              for help.
            </p>
            <Link to='/' className='primary-button'>
              Head Back Home
              <div className='primary-button__icon'>
                <div className='icon-right-arrow-icon'></div>
              </div>
            </Link>
          </div>
          <div className='hero-1__hero-image hero-1__hero-image--404'>
            <div className='hero-1__image-wrapper'>
              <div className='hero-1__image hero-1__image--404'>
                404
              </div>
              <div className='hero-1__bg-pattern hero-1__bg-pattern--home'>
                <img src='/images/hero-home-pattern.png' alt='' />
              </div>
            </div>
          </div>
          <div className='hero-1__col-2'>
            <SocialNetworks wrapperClassList='hero-1__hero-social'>
              <div className='social-layout-1__seperator'></div>
              <a className='social-layout-1__email' href='mailto:hello@wyeworks.com'>
                hello@wyeworks.com
              </a>
            </SocialNetworks>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
